<template>
    <div v-if="(roles & 1) !== 0">
        <div v-if="isLive" class="alert alert-danger" style="text-align: center; font-weight: bold;" role="alert">
            You are Managing the Live Server Data
        </div>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-speedometer"/><strong> Dashboard </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            Below you will find the Active Sessions for the <span class="mx-2"><CBadge shape="pill" :color=pillClr style="min-width: 80px;" >{{ currentEnv }}</CBadge></span>  environment
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                        <CButton color="primary" class="px-4" @click="getUserSessions" :disabled="isLoading">
                            <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" style="margin-right: 15px;" />
                            {{ btnTxt }}
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <CIcon name="cil-people"/><strong>  Active Players </strong>
                            <div class="card-header-actions">
                                <small class="text-muted">Total: {{ userSessions.length }}</small>
                            </div>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :key="dataKey"
                                :items="userSessions"
                                :fields="userFields"
                                :hover="hover"
                                :loading="isLoading"
                                column-filter
                                items-per-page-select
                                :items-per-page="10"
                                sorter
                                pagination >
                            <template #flags="{item}">
                                <td>
                                    <CBadge :color="$config.userClr(item.flags)">{{ $config.userTxt(item.flags) }}</CBadge>
                                </td>
                            </template>
                            <template #created="{item}">
                                <td class="py-2">{{ item.created | formatDate }}</td>
                            </template>
                            <template #last_active="{item}">
                                <td class="py-2">{{ item.last_active | formatDate }}</td>
                            </template>
                            <template #manage="{item}">
                                <td class="py-2">
                                    <CButton
                                            color="primary"
                                            variant="outline"
                                            square
                                            size="sm"
                                            @click="manageUser(item.id)">
                                        Manage
                                    </CButton>
                                </td>
                            </template>
                            <template #remove="{item}">
                                <td class="py-2">
                                    <CButton
                                            color="danger"
                                            variant="outline"
                                            square
                                            size="sm"
                                            @click="removeSession(item.id)">
                                        <CIcon name="cil-trash"/>
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <CIcon name="cil-terrain"/><strong> Active Zones </strong>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :key="dataKey"
                                :items="zoneSessions"
                                :fields="zoneFields"
                                :hover="hover"
                                :loading="isLoading"
                                pagination >
                            <template #flags="{item}">
                                <td>
                                    <CBadge :color="$config.flagClr(item.flags)">{{ $config.flagTxt(item.flags) }}</CBadge>
                                </td>
                            </template>
                            <template #lastActive="{item}">
                                <td class="py-2">{{ item.lastActive | formatDate }}</td>
                            </template>
                            <template #manage="{item}">
                                <td class="py-2">
                                    <CButton
                                            color="primary"
                                            variant="outline"
                                            square
                                            size="sm"
                                            @click="manageZone(item.zoneRecordId)">
                                        Manage
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <CIcon name="cil-globe-alt"/><strong> Active Worlds </strong>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :key="dataKey"
                                :items="worldSessions"
                                :fields="worldFields"
                                :hover="hover"
                                :loading="isLoading"
                                pagination >
                            <template #status="{item}">
                                <td>
                                    <CBadge :color="$config.flagClr(item.flags)">{{ $config.flagTxt(item.flags) }}</CBadge>
                                </td>
                            </template>
                            <template #last_active="{item}">
                                <td class="py-2">{{ item.last_active | formatDate }}</td>
                            </template>
                            <template #manage="{item}">
                                <td class="py-2">
                                    <CButton
                                            color="primary"
                                            variant="outline"
                                            square
                                            size="sm"
                                            @click="manageWorld(item.world_id)">
                                        Manage
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
    <div v-else>
        <CRow>
            <CCol>
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-speedometer"/><strong> Dashboard </strong>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            Welcome to the Stormhaven Studios GM Portal
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>

    export default {
        name: 'Dashboard',
        data() {
            return {
                roles: 0,
                btnTxt: "Refresh",
                isLoading: false,
                hover: true,
                userSessions: [],
                userFields: [
                    { key: "userName", label: "Username" },
                    { key: 'email', label: 'Email' },
                    { key: 'flags', label: 'Flag', filter: false, sorter: false },
                    { key: 'created', label: 'Created', filter: false, sorter: false },
                    { key: 'last_active', label: 'Last Active', filter: false, sorter: false },
                    { key: 'manage', label: '', filter: false, sorter: false },
                    { key: 'remove', label: '', filter: false, sorter: false }
                ],
                zoneSessions: [],
                zoneFields: [
                    { key: "zoneId", label: "ID" },
                    { key: 'sceneName', label: 'Scene' },
                    { key: 'displayName', label: 'Name' },
                    { key: 'address', label: 'IP' },
                    { key: 'port', label: 'Port' },
                    { key: 'defaultPopulationThreshold', label: 'Default Pop' },
                    { key: 'populationThreshold', label: 'Pop Threshold' },
                    { key: 'playerCount', label: 'Pop' },
                    { key: 'lastActive', label: 'Last Active' },
                    { key: 'flags', label: 'Status' },
                    { key: 'manage', label: '' },
                ],
                worldSessions: [],
                worldFields: [
                    { key: "world_id", label: "ID" },
                    { key: 'world_name', label: 'Name' },
                    { key: 'ip', label: 'IP' },
                    { key: 'port', label: 'Port' },
                    { key: 'last_active', label: 'Last Active' },
                    { key: 'status', label: 'Status' },
                    { key: 'manage', label: '' },
                ],
                dataKey: 0,
                currentEnv: localStorage.env
            }
        },
        computed: {
            pillClr: function () {
                if (localStorage.env === "qa" ) { return "warning"; } else
                if (localStorage.env === "live" ) { return "danger"; } else
                { return "success"; }
            },
            isLive: function () {
                return (localStorage.env === "live");
            }
        },
        watch: {
            isUpdating: function(){
                this.btnTxt = (this.isLoading ? 'Please Wait...' : 'Refresh');
            }
        },
        methods: {
            getUserSessions() {
                this.isLoading = true;
                this.$http.get(this.$config.gameapi + '/Player/' + localStorage.env + '/active', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.userSessions = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    let m = (!error.body && !error.body.error) ? error.body.error : "An Unknown Error Has Occurred";
                    this.$root.$emit('sendMsg', "API Error (" + error.status +")", "danger", m );
                }).finally(function (){
                    this.getZoneSessions();
                });
            },
            getZoneSessions() {
                this.$http.get(this.$config.gameapi + '/Zone/' + localStorage.env + '/active', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.zoneSessions = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.getWorldSessions();
                });
            },
            getWorldSessions() {
                this.$http.get(this.$config.gameapi + '/World/' + localStorage.env + '/Status', { headers: this.$config.getHeaders() }).then(response => {
                    return response.json();
                }).then(jsonData => {
                    this.worldSessions = jsonData;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.isLoading = false;
                    this.dataKey++;
                });
            },
            removeSession(id) {
                this.isLoading = true;
                this.$http.delete(this.$config.gameapi + '/Player/' + localStorage.env + '/' + id, { headers: this.$config.getHeaders() }).then(response => {
                    return;
                }).catch(function (error) {
                    this.isLoading = false;
                    this.$root.$emit('onErr', error);
                }).finally(function (){
                    this.getUserSessions();
                });
            },
            manageWorld(id) {
                this.$router.push({ name: 'World', params: { id: id } })
            },
            manageZone(id) {
                this.$router.push({ name: 'Zone', params: { id: id } })
            },
            manageUser(id) {
                this.$router.push({ name: 'Player', params: { id: id } })
            },
        },
        created() {
            this.roles = parseInt(localStorage.roles);

            if ((this.roles & 1) !== 0) {
                this.getUserSessions();
            }
        }
    }
</script>
